import React, {Component} from 'react';
import {DayPilot, DayPilotCalendar, DayPilotNavigator} from "@daypilot/daypilot-lite-react";
import "./CalendarStyles.css";

import { auth, db } from '../firebase'
import { doc, getDoc, setDoc, collection, deleteDoc, DocumentData, getDocs, limit, orderBy, query, QueryDocumentSnapshot, serverTimestamp, startAfter, where } from '@firebase/firestore'
import 'firebase/firestore';
import Modal from 'react-modal';

const styles = {
  wrap: {
    display: "flex",
    marginTop: "10px"
  },
  left: {
    marginRight: "10px"
  },
  main: {
    flexGrow: "1"
  }
};

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999,
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '320px',
    width: '100%',
    height: '50%',
    padding: '20px',
    border: 'none',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
    background: '#fff',
    outline: 'none',
    margin: "10px"
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  label: {
    marginBottom: '4px',
  },
  input: {
    marginLeft: '4px',
    padding: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '14px',
  },
  submitButton: {
    padding: '8px 16px',
    background: 'blue',
    color: '#fff',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

const getEvents = async () => {

  let today = new Date()

  let snapshot = await getDocs(query(collection(db, "events"), where("start_timestamp", ">", today)));

    let eventList = [];
    if (!snapshot.empty) {
      for (let i = 0; i < snapshot.docs.length; i++) {
        let data = snapshot.docs[i].data();
        eventList.push({...data, text: `${data.text} (${data.skillLevel})`});
      }
    }
    return eventList
}

class GeneralCalendar extends Component {

  constructor(props) {
    // let currentUser = auth().currentUser;


    super(props);
    this.calendarRef = React.createRef();
    this.state = {
      viewType: "Week",
      durationBarVisible: false,
      timeRangeSelectedHandling: "Disabled",
      headerDateFormat: "dddd",
      eventDeleteHandling: "Disabled",
      eventMoveHandling: "Disabled",
      onEventClick: async args => {
        // alert("EVENT selected")
        
        const dp = this.calendar;
        // const modal = await DayPilot.Modal.prompt("Update event text:", args.e.text());
        // if (!modal.result) { return; }
        const e = args.e;
        this.openModal(args, `Schedule a chess match with ${e.data.text}?`)

      },
    };
  }

  get calendar() {
    return this.calendarRef.current.control;
  }

  componentDidMount() {

    
    // let events = []


    // const startDate = "2023-03-07";
    let startDate = new Date().toISOString().slice(0, 10);

    getEvents().then(
      (events) =>
      {
        this.calendar.update({startDate, events});
      }
    )

    // this.calendar.update({startDate, events});

  }


  state = {
    isOpen: false,
    name: '',
    email: '',
    isOnline: true,
  };

  openModal = (args, modal_title) => {
    this.setState({ isOpen: true });
    this.setState({ isOnline: true });
    this.setState({ modal_title: modal_title });
    this.setState({ args: args });
  };

  closeModal = () => {
    this.setState({ isOpen: false });
    
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here, e.g., send data to server
    console.log('Name:', this.state.name);
    console.log('Email:', this.state.email);
    console.log('Is Online:', this.state.isOnline);
    this.submitToDb(this.state.email, this.state.name)
    this.closeModal();
  };

  submitToDb = async (email, citizen_name) => {
    if (!(email == null || email == "")) {
      let id = this.state.args.e.data.id;


      const docRef = doc(db, "events", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());

        var newDate = new Date();
        newDate.setTime(docSnap.data().start_timestamp.seconds*1000);
        let dateString = newDate.toUTCString();
        console.log(dateString)

        fetch('/send-email', {  // Enter your IP address here

          method: 'POST', 
          headers: {
            'Content-Type': 'application/json'
          },
          
          body: JSON.stringify({"volunteer_email": docSnap.data().email, "citizen_email": email, "time": dateString, "volunteer_name": docSnap.data().text, "citizen_name": citizen_name, "online": this.state.isOnline}) // body data type must match "Content-Type" header

        })
        deleteDoc(docRef);
        alert("Game scheduled sucessfully! You will recieve an email with a zoom link and game link")

      } else {
        console.log("No such document!");
      }

      
      let startDate = new Date().toISOString().slice(0, 10);
      getEvents().then(
        (events) =>
        {
          this.calendar.update({startDate, events});
        }
      )
    }
  }

 

  render() {
    let today = new Date().toISOString().slice(0, 10);
    const { isOpen, name, email, isOnline, modal_title, args } = this.state;
    return (

      <div>
        <button className="btn btn-primary btn-lg mt-2 mb-2 ml-1" onClick={() => {window.location.reload()}}>Log out</button>
      <div>
        <Modal
          isOpen={isOpen}
          onRequestClose={this.closeModal}
          contentLabel="Modal"
          style={modalStyles}
        >
          <h4>{modal_title}</h4>
          <br></br>
          <form style={modalStyles.form} onSubmit={this.handleSubmit}>
            <label style={modalStyles.label} htmlFor="name">
              Name:
              <input
                style={modalStyles.input}
                type="text"
                id="name"
                value={name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </label>
            <label style={modalStyles.label} htmlFor="email">
              Email:
              <input
                style={modalStyles.input}
                type="email"
                id="email"
                value={email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </label>
            <div>
              <label style={modalStyles.label} htmlFor="isOnline">
                Meet online?
              </label>
              {/* <input
                style={modalStyles.input}
                type="checkbox"
                id="isOnline"
                checked={isOnline}
                onChange={(e) => this.setState({ isOnline: e.target.checked })}
              /> */}
              <div style={{display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#D3D3D3",
                    borderRadius: 15,
                    padding: "3px"}}>
              <div style={{
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: isOnline ? "white": "transparent",
                      width: "50%",
                      height: 40,
                      borderRadius: 15,
                      alignItems: "center",
                    }}
                    onClick={() => this.setState({ isOnline: true })}
              >Online</div>
              <div style={{
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: !isOnline ? "white": "transparent",
                      width: "50%",
                      height: 40,
                      borderRadius: 15,
                      alignItems: "center",
                    }}
                    onClick={() => this.setState({ isOnline: false })}
              >In person</div>
              </div>
            </div>
            
            <button style={modalStyles.submitButton} type="submit">
              Submit
            </button>
          </form>
        </Modal>
      </div>

      <div style={styles.wrap}>

        
        <div style={styles.left}>
          <DayPilotNavigator
            selectMode={"week"}
            showMonths={1}
            skipMonths={1}
            startDate={today}
            selectionDay={today}
            onTimeRangeSelected={ args => {
              this.calendar.update({
                startDate: args.day
              });
            }}
          />
        </div>
        <div style={styles.main}>
          <DayPilotCalendar
            {...this.state}
            ref={this.calendarRef}
          />
        </div>
      </div>

      </div>
    );
  }
}

export default GeneralCalendar;
