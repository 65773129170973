import React from 'react';

function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <a className="navbar-brand" href="#">Chess Volunteers</a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a className="nav-link" href="#">Log In</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">Schedule a Game</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

function HeroSection({ signin_student, signin_citizen }) {
  return (
    <header className="hero bg-primary text-white text-center mb-5">
      <div className="container mb-5 pt-5">
        <h1 className="display-4">Play Chess with Senior Citizens</h1>
        <p className="lead">Make a Difference. Volunteer Your Time.</p>
        <button onClick={signin_citizen} className="btn btn-light btn-lg mb-3">Senior citizen entry</button><br></br>
        <button onClick={signin_student} className="btn btn-light btn-lg mb-5">Student log in</button>
        <br/>
      </div>
    </header>
  );
}

function AboutSection() {
  return (
    <section className="about py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h2>About Chess Volunteers</h2>
            <p className="lead">Chess Volunteers is a platform that connects students with senior citizens who share a love for chess. Whether you want to play virtually or in person, we provide an opportunity to engage, connect, and create meaningful experiences.</p>
          </div>
          <div className="col-lg-6">
            <img src="https://images.pexels.com/photos/163427/chess-figure-game-play-163427.jpeg?cs=srgb&dl=pexels-pixabay-163427.jpg&fm=jpg" alt="About Image" className="img-fluid" />
          </div>
        </div>
      </div>
    </section>
  );
}

function CTASection({ signin_student }) {
  return (
    <section className="cta bg-light py-5">
      <div className="container text-center">
        <h2>Ready to Make a Move?</h2>
        <p className="lead">Become a Chess Volunteer today and brighten someone's day.</p>
        <a href="#" onClick={signin_student} className="btn btn-primary btn-lg">Join Now</a>
      </div>
    </section>
  );
}

function Footer() {
  return (
    <footer className="footer bg-dark text-white py-4">
      <div className="container text-center">
        <p>&copy; 2023 Chess Volunteers. All rights reserved.</p>
      </div>
    </footer>
  );
}



export function LandingPage({signin_student, signin_citizen}) {
  return (
    <div>
      <HeroSection signin_student={signin_student} signin_citizen={signin_citizen}/>
      <AboutSection />
      <CTASection signin_student={signin_student}/>
      <Footer />
    </div>
  );
}