import React, {Component} from 'react';
import './App.css';
import Calendar from "./calendar/Calendar";
import GeneralCalendar from "./calendar/GeneralCalendar";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from './firebase'
import { GoogleAuthProvider, signInWithPopup, signInWithRedirect } from "firebase/auth";
import { getFirestore, query, getDocs, collection, where, addDoc } from "firebase/firestore"
import { useState } from 'react';
import { LandingPage } from './screens/LandingPage';


function App() {
  const [user] = useAuthState(auth);
  const [userDoc, setUserDoc] = useState(false);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const res = await signInWithPopup(auth, provider);
      const user = res.user;
      const q = query(collection(db, "users"), where("uid", "==", user.uid));
      const docs = await getDocs(q);
      if (docs.docs.length === 0) {
        await addDoc(collection(db, "users"), {
          uid: user.uid,
          name: user.displayName,
          authProvider: "google",
          email: user.email,
        });
      } else {

      }
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
    
  }

  function SignIn() {

    const signInWithGoogle = async () => {
      const provider = new GoogleAuthProvider();
  
      try {
        const res = await signInWithPopup(auth, provider);
        const user = res.user;
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
          await addDoc(collection(db, "users"), {
            uid: user.uid,
            name: user.displayName,
            authProvider: "google",
            email: user.email,
          });
        } else {
  
        }
      } catch (err) {
        console.error(err);
        alert(err.message);
      }
      
    }
  
    return (
      <>
        <button className="sign-in" onClick={signInWithGoogle}>Student Sign in with Google</button>
        <button className="sign-out" onClick={() => setUserDoc(true)}>Senior citizen log in</button>
      </>
    )
  
  }
  

  function SignOut() {
    // auth().currentUser
    return (
      <div>
        <br></br>
      <right>
      <button className="btn btn-primary btn-lg mb-2 ml-1" onClick={() => auth.signOut()}>Sign Out</button>
      </right>
      </div>
      )
  }

  const Navbar = () => {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <span className="navbar-brand left-align">Chess Volunteers</span>
      </nav>
    );
  };

  function foo() {
    if (user) {
      return (<><Navbar />< SignOut /><Calendar /></>);
    } else if (userDoc) {
      return (<><Navbar /><GeneralCalendar /></>)
    } else {
      // return (<SignIn />)
      return (<><Navbar /><LandingPage signin_student={signInWithGoogle} signin_citizen={() => setUserDoc(true)}/></>)
    }
  }


    return (
      <section>        
        {/* {user ? <Calendar /> : <SignIn />} */}
        {foo()}
        </ section>
    );
}

export default App;
