import { getStorage } from '@firebase/storage';
import { initializeApp, getApps } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore, initializeFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDQmTBeYshoS9q9tdbGkcBe_TCPhLiDpTM",
    authDomain: "spielmvp.firebaseapp.com",
    projectId: "spielmvp",
    storageBucket: "spielmvp.appspot.com",
    messagingSenderId: "164298366302",
    appId: "1:164298366302:web:d4e905a3804734af9cc3cf",
    measurementId: "G-J5E58WDM3G"
  };

  let app = null
  let db = null
  let storage = null
  let auth = null;
  

  if (getApps().length < 1) {
    app = initializeApp(firebaseConfig)
    auth = getAuth()
  }

  if (app) {
    db = getFirestore(app)
    storage = getStorage(app)
  }

  

export { db, app, storage, auth }