import React, {Component, useState} from 'react';
import {DayPilot, DayPilotCalendar, DayPilotNavigator} from "@daypilot/daypilot-lite-react";
import "./CalendarStyles.css";

import { auth, db } from '../firebase'
import { doc, setDoc, collection, deleteDoc, DocumentData, getDocs, limit, orderBy, query, QueryDocumentSnapshot, serverTimestamp, startAfter, where, updateDoc } from '@firebase/firestore'
import 'firebase/firestore';
import Modal from 'react-modal';

const styles = {
  wrap: {
    display: "flex"
  },
  left: {
    marginRight: "10px"
  },
  main: {
    flexGrow: "1"
  }
};

const getEvents = async () => {

  const currentUser = auth.currentUser;
  const uid = currentUser.uid;
  const user_email = currentUser.email;

  let today = new Date()
  console.log(today)
  // today = "2023-03-10T10:00:00";
  // where("uid", "==", uid),
  let snapshot = await getDocs(query(collection(db, "events"), where("start_timestamp", ">", today)));

  // let snapshot = await spielsRef.where("user_id", "in", followingList).orderBy("timestamp", "desc").startAfter(lastDoc.data().timestamp).limit(10).get()

    let eventList = [];
    if (!snapshot.empty) {
      for (let i = 0; i < snapshot.docs.length; i++) {
        let data = snapshot.docs[i].data();
        eventList.push({...data});
      }
    }
    return eventList
}

const skillLevelSet = async () => {
  const currentUser = auth.currentUser;
  const uid = currentUser.uid;
  const q = query(collection(db, "users"), where("uid", "==", uid));
  const docs = await getDocs(q);
  console.log(docs.docs.length)
  if (docs.docs.length === 0) {
  } else {
    let user_doc = docs.docs[0].data()
    console.log(user_doc)
    if (user_doc.skillLevel) {
      return true
    }
  }
  return false
}

const getSkillLevel = async () => {
  const currentUser = auth.currentUser;
  const uid = currentUser.uid;
  const q = query(collection(db, "users"), where("uid", "==", uid));
  const docs = await getDocs(q);
  console.log(docs.docs.length)
  if (docs.docs.length === 0) {
  } else {
    let user_doc = docs.docs[0].data()
    console.log(user_doc)
    if (user_doc.skillLevel) {
      return user_doc.skillLevel
    }
  }
  return "beginner"
}

const customStyles = {
  content: {
    width: '600px',
    height: '300px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
const ChessLevelModal = ({ isOpen, onClose, onSelectLevel, close }) => {
  const [selectedLevel, setSelectedLevel] = useState('');

  const handleLevelSelection = (level) => {
    setSelectedLevel(level);
  };

  const handleSubmit = async () => {
    const currentUser = auth.currentUser;
    const uid = currentUser.uid;
    if (selectedLevel !== '') {

      const q = query(collection(db, "users"), where("uid", "==", uid));
      const docs = await getDocs(q);
      console.log(docs.docs.length)
      if (docs.docs.length === 0) {
      } else {
        let user_doc = docs.docs[0]
        let id = user_doc.id
        await updateDoc(doc(db, "users", id), {
          skillLevel: selectedLevel
        });
      }



      onSelectLevel(selectedLevel);
      setSelectedLevel('');
      close()
    }
    
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Chess Level Modal"
      style={customStyles}
    >
      <h2>Select Your Chess Level</h2>
      <div>
        <label>
          <input
            type="radio"
            name="chessLevel"
            value="beginner"
            checked={selectedLevel === 'beginner'}
            onChange={() => handleLevelSelection('beginner')}
          /> Beginner
        </label>
      </div>
      <div>
        <label>
          <input
            type="radio"
            name="chessLevel"
            value="intermediate"
            checked={selectedLevel === 'intermediate'}
            onChange={() => handleLevelSelection('intermediate')}
          /> Intermediate
        </label>
      </div>
      <div>
        <label>
          <input
            type="radio"
            name="chessLevel"
            value="advanced"
            checked={selectedLevel === 'advanced'}
            onChange={() => handleLevelSelection('advanced')}
          /> Advanced
        </label>
      </div>
      <button onClick={handleSubmit}>Submit</button>
    </Modal>
  );
};

class Calendar extends Component {

  constructor(props) {
    // let currentUser = auth().currentUser;

    super(props);
    this.calendarRef = React.createRef();
    this.state = {
      viewType: "Week",
      durationBarVisible: false,
      timeRangeSelectedHandling: "Enabled",
      headerDateFormat: "dddd",
      onTimeRangeSelected: async args => {

        let currentUser = auth.currentUser;
        console.log(currentUser);
        let name = currentUser.displayName;
        let uid = currentUser.uid;
        let user_email = currentUser.email;

        const dp = this.calendar;
        let start = args.start;
        console.log(start)
        console.log(start.toDateLocal())
        const modal = await DayPilot.Modal.confirm(`Schedule your availability at ${args.start.value}`);
        dp.clearSelection();
        if (!modal.result) { return; }
        let id = DayPilot.guid();
        let text = `${name}`
        dp.events.add({
          start: args.start,
          end: args.end,
          id: id,
          text: text
        });

        let skillLevel = await getSkillLevel();
        setDoc(doc(db, "events", id),
        {
          text: modal.result,
          createdAt: serverTimestamp(),
          start: args.start.value,
          start_timestamp: args.start.toDateLocal(),
          end_timestamp: args.end.toDateLocal(),
          end: args.end.value,
          id: id,
          uid: uid,
          email: user_email,
          text: text,
          skillLevel: skillLevel
        })
      },
      eventDeleteHandling: "Update",
      onEventDelete: async args => {
        let id = args.e.data.id;
        deleteDoc(doc(db, "events", id))
      },
      onEventClick: async args => {
        // const dp = this.calendar;
        // const modal = await DayPilot.Modal.prompt("Update event text:", args.e.text());
        // if (!modal.result) { return; }
        // const e = args.e;
        // e.data.text = modal.result;
        // dp.events.update(e);
      },
      isOpen: false
    };
  }

  get calendar() {
    return this.calendarRef.current.control;
  }

  componentDidMount() {


    // const startDate = "2023-03-07";
    let startDate = new Date().toISOString().slice(0, 10);
    getEvents().then(
      (events) =>
      {
        this.calendar.update({startDate, events});
      }
    )

    skillLevelSet().then(isSet => {
      this.setState({ isOpen: !isSet });
    }

    )

    // this.calendar.update({startDate, events});

  }

 

  render() {
    let today = new Date().toISOString().slice(0, 10);
    return (
      <div>
        <ChessLevelModal
        isOpen={this.state.isOpen}
        close={()=>{this.setState({ isOpen: false });}}
        onClose={()=>{}}
        onSelectLevel={()=>{}}
      />
      <div style={styles.wrap}>
        <div style={styles.left}>
          <DayPilotNavigator
            selectMode={"week"}
            showMonths={1}
            skipMonths={1}
            startDate={today}
            selectionDay={today}
            onTimeRangeSelected={ args => {
              this.calendar.update({
                startDate: args.day
              });
            }}
          />
        </div>
        <div style={styles.main}>
          <DayPilotCalendar
            {...this.state}
            ref={this.calendarRef}
          />
        </div>
      </div>
      </div>
    );
  }
}

export default Calendar;
